import React, { useState } from 'react';
import style from './index.module.scss';
import Modal from '../../components/modal';

function Support() {
  const [showSupport, setShowSupport] = useState(false);
  return (
    <>

      {/* Support Options Modal */}
      <Modal
        show={showSupport}
        size="sm"
        onHide={() => {
          setShowSupport(false);
        }}
      >
        <div
          className="p-4"
        >
          <button
            type="button"
            onClick={() => {
              setShowSupport(false);
              window.open('tel:02248904547');
            }}
            className="text-blue text-lg font-semibold"
          >
            Call To -&nbsp;
            02248904547
          </button>
        </div>
      </Modal>

      {/* Support Button */}
      <button
        type="button"
        className={`py-2 px-4 bg-blue text-white text-lg font-black ${style.supportButton}`}
        onClick={() => {
          setShowSupport(true);
        }}
      >
        Support
      </button>
    </>
  );
}

Support.propTypes = {};

export default Support;
