import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import style from './index.module.scss';
import OrderCard from '../orders/order-card';
import OrderProducts from './order-products';
import orderApis from '../../apis/order';
import { getBrandId } from '../../utilities/storage';
import CircularSpinner from '../../components/loader';
import Modal from '../../components/modal';

function OrderDetails() {
  const brandId = getBrandId();
  const params = useParams();
  const { orderId } = params;

  const [orderDetails, setOrderDetails] = useState({
    loading: true,
    error: false,
    data: null,
  });
  const [showOrderConfirmationModal, setShowOrderConfirmationModal] = useState(null);

  const getOrderDetails = () => {
    orderApis.orderDetails(orderId, brandId).then((res) => {
      setOrderDetails({
        ...orderDetails,
        loading: false,
        error: false,
        data: res.data.data,
      });
    }).catch(() => {
      setOrderDetails({
        ...orderDetails,
        loading: false,
        error: true,
        data: null,
      });
    });
  };

  const patchOrderDetails = (data = {}) => {
    orderApis.orderDetailsPatch(orderId, data).then((res) => {
      setOrderDetails({
        ...orderDetails,
        data: res.data.data,
      });
    }).catch(() => {
      //
    });
  };

  const onModifyOrder = (data = {}) => {
    if (data.orderStatus === 'ACCEPTED') {
      patchOrderDetails(data);
    } else {
      setShowOrderConfirmationModal(data);
    }
  };

  useEffect(() => {
    getOrderDetails();
  }, []);

  const appliedOffers = (
    (
      orderDetails
      && orderDetails.data
      && orderDetails.data.appliedOffers
    ) || []
  );

  return (
    <div
      className="my-4 mx-4 relative"
    >
      <Modal
        show={!!showOrderConfirmationModal}
        onHide={() => {
          setShowOrderConfirmationModal(null);
        }}
        size="md"
      >
        <div
          className={`bg-white p-4 ${style.modalContainer}`}
        >
          <div
            className="font-bold text-xl"
          >
            Are you sure?
          </div>
          {
            showOrderConfirmationModal && (
              <>
                <div
                  className="pt-3 text-lg"
                >
                  Are you sure you want to
                  &nbsp;
                  <b>
                    {showOrderConfirmationModal.orderStatus}
                  </b>
                  &nbsp;
                  this order?
                </div>
                <div
                  className="pt-3"
                >
                  <button
                    type="button"
                    onClick={() => {
                      patchOrderDetails(showOrderConfirmationModal);
                      setShowOrderConfirmationModal(null);
                    }}
                    className={`py-2 bg-blue text-white text-lg font-bold ${style.actionButton}`}
                  >
                    SUBMIT
                  </button>
                </div>
              </>
            )
          }
        </div>
      </Modal>
      {
        (() => {
          let orderDetailsJSX = null;
          if (orderDetails.loading) {
            orderDetailsJSX = (
              <div
                className="flex justify-center"
              >
                <CircularSpinner />
              </div>
            );
          } else if (orderDetails.error) {
            orderDetailsJSX = (
              <div>
                OOPs Something Went Wrong
              </div>
            );
          } else if (orderDetails.data) {
            const orderDetailsData = orderDetails.data;
            orderDetailsJSX = (
              <>
                <div
                  className={`${style.sectionBox} bg-white p-4 mb-3 flex items-center justify-center`}
                >
                  {
                    orderDetailsData.brandStatus === 'CANCELLED' && (
                      <div
                        className="text-red text-2xl"
                      >
                        Order is Cancelled
                      </div>
                    )
                  }
                  {
                    orderDetailsData.brandStatus === 'DELIVERED' && (
                      <div
                        className="text-green text-2xl"
                      >
                        Order is Delivered
                      </div>
                    )
                  }
                  {
                    orderDetailsData.brandStatus === 'PENDING' ? (
                      <>
                        <div
                          className="w-2/4"
                        >
                          <button
                            type="button"
                            className={`py-2 mr-1 px-2 bg-red text-white ${style.actionButton}`}
                            onClick={() => {
                              onModifyOrder({
                                orderStatus: 'CANCELLED',
                              });
                            }}
                          >
                            REJECT ORDER
                          </button>
                        </div>
                        <div
                          className="w-2/4"
                        >
                          <button
                            type="button"
                            className={`py-2 px-2 ml-1 bg-green text-white ${style.actionButton}`}
                            onClick={() => {
                              onModifyOrder({
                                orderStatus: 'ACCEPTED',
                              });
                            }}
                          >
                            ACCEPT ORDER
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {
                        orderDetailsData.brandStatus === 'ACCEPTED' && (
                          <div
                            className="w-3/4"
                          >
                            <button
                              type="button"
                              className={`py-2 px-2 ml-1 bg-green text-white ${style.actionButton}`}
                              onClick={() => {
                                onModifyOrder({
                                  orderStatus: 'READY_TO_SHIP',
                                });
                              }}
                            >
                              READY TO SHIP
                            </button>
                          </div>
                        )
                      }
                        {
                          orderDetailsData.brandStatus === 'READY_TO_SHIP' && (
                            <div
                              className="w-3/4"
                            >
                              <button
                                type="button"
                                className={`py-2 px-2 ml-1 bg-green text-white ${style.actionButton}`}
                                onClick={() => {
                                  onModifyOrder({
                                    orderStatus: 'OUT_FOR_DELIVERY',
                                  });
                                }}
                              >
                                OUT FOR DELIVERY
                              </button>
                            </div>
                          )
                        }
                        {
                          orderDetailsData.brandStatus === 'OUT_FOR_DELIVERY' && (
                            <div
                              className="w-3/4"
                            >
                              <button
                                type="button"
                                className={`py-2 px-2 ml-1 bg-green text-white ${style.actionButton}`}
                                onClick={() => {
                                  onModifyOrder({
                                    orderStatus: 'DELIVERED',
                                  });
                                }}
                              >
                                MARK DELIVERED
                              </button>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                </div>
                <div
                  className={`${style.sectionBox} p-3 bg-white`}
                >
                  <OrderCard
                    isOrderDetails
                    order={{
                      orderId,
                      productCount: orderDetailsData.productCount,
                      createdAt: orderDetailsData.createdAt,
                      brand_status: {
                        identifier: orderDetailsData.brandStatus,
                      },
                      payment: {
                        ...orderDetailsData.payment,
                      },
                      store: {
                        ...orderDetailsData.store,
                      },
                      deliveryAddress: orderDetailsData.deliveryAddress,
                    }}
                  />
                </div>
                <div
                  className={`${style.sectionBox} mt-3 p-3 bg-white`}
                >
                  <div
                    className="pb-3 font-extrabold text-lg"
                  >
                    Order Items
                    &nbsp;
                    (
                    {orderDetailsData.productCount}
                    )
                  </div>
                  {
                    orderDetailsData.retailerProducts.map((item) => (
                      <div
                        key={item.productId}
                      >
                        <OrderProducts
                          product={item}
                        />
                      </div>
                    ))
                  }
                  {
                    appliedOffers.map((item) => (
                      <div
                        key={item.id}
                        className={`mb-1 border-green-72 p-3 flex items-center ${style.offerBox}`}
                      >
                        <div
                          className=""
                        >
                          <img src={item.image} alt={item.name} width="56px" height="56px" />
                        </div>
                        <div
                          className="pl-3"
                        >
                          <div>
                            {item.name}
                          </div>
                          <div>
                            {item.description}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
                <div
                  className={`${style.sectionBox} mt-3 p-3 bg-white`}
                >
                  <div
                    className="pb-3 font-extrabold text-lg"
                  >
                    Bill Details
                  </div>
                  <div
                    className={`${style.billSection}`}
                  >
                    {[
                      {
                        id: 1,
                        name: 'Item Total',
                        value: orderDetailsData.payment.billBeforeDiscount,
                      },
                      {
                        id: 2,
                        name: 'Overall Discount',
                        value: orderDetailsData.payment.overallDiscount,
                      },
                      {
                        id: 3,
                        name: 'Total Paid',
                        value: orderDetailsData.payment.amountPaid,
                      },
                      {
                        id: 4,
                        name: 'Pending Payment',
                        value: orderDetailsData.payment.amountPending,
                      },
                      {
                        id: 5,
                        name: 'Total Bill',
                        value: orderDetailsData.payment.amountPayable,
                      },
                    ].map((item) => (
                      <div
                        key={item.id}
                        className="pt-1 flex items-center justify-between border-b-2 border-gray-lighter"
                      >
                        <div
                          className="w-1/3"
                        >
                          {item.name}
                        </div>
                        <div
                          className="w-2/3 text-right font-bold"
                        >
                          ₹
                          {item.value}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            );
          }
          return orderDetailsJSX;
        })()
      }
    </div>
  );
}

OrderDetails.propTypes = {
};

export default OrderDetails;
