import { createStore, applyMiddleware, combineReducers } from 'redux';
import logger from 'redux-logger';
import profileReducer from './profile/reducer';
import profileMiddleware from './profile/middleware';
import orderReducer from './order/reducer';
import orderMiddleware from './order/middleware';
// import APIMiddleware from './APIMiddleware';
// import MainReducer from './MainReducer';

export default createStore(
  combineReducers({
    profile: profileReducer,
    order: orderReducer,
  }),
  applyMiddleware(
    ...[profileMiddleware, logger],
    ...[orderMiddleware, logger],
  ),
);
