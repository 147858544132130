import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../../components/modal';
import style from './index.module.scss';
import { validateRegex } from '../../../utilities/utils';

function ProductEdit(props) {
  const {
    product, show, onHide, patchProduct,
  } = props;
  const [editable, setEditable] = useState({
    name: product.name,
    retailPrice: product.retailPrice,
    wholeSalePrice: product.wholeSalePrice,
    minimumOrderQuantity: product.minimumOrderQuantity,
  });

  const isSaveButtonDisabled = (
    !editable.name
    || !editable.retailPrice
    || !editable.wholeSalePrice
    || !editable.minimumOrderQuantity
  );

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
      }}
      size="md"
    >
      <div
        className={`p-4 bg-white ${style.modalContainer}`}
      >
        <div
          className="font-extrabold text-xl"
        >
          Edit Product
        </div>
        <div
          className="pt-4"
        >
          <p
            className="font-bold text-sm"
          >
            Name
          </p>
          <input
            className={`mt-2 border border-gray ${style.textInputBox}`}
            type="text"
            name="name"
            value={editable.name}
            maxLength={100}
            onChange={(e) => {
              setEditable({
                ...editable,
                name: e.target.value,
              });
            }}
          />
        </div>

        <div
          className="pt-4"
        >
          <p
            className="font-bold text-sm"
          >
            Retail Price
          </p>
          <input
            className={`mt-2 border border-gray ${style.textInputBox}`}
            type="text"
            name="retailPrice"
            value={editable.retailPrice}
            maxLength={10}
            onChange={(e) => {
              if (!validateRegex('number', e.target.value)) {
                return;
              }
              setEditable({
                ...editable,
                retailPrice: e.target.value,
              });
            }}
          />
        </div>

        <div
          className="pt-4"
        >
          <p
            className="font-bold text-sm"
          >
            Whole Sale Price
          </p>
          <input
            className={`mt-2 border border-gray ${style.textInputBox}`}
            type="text"
            name="wholeSalePrice"
            value={editable.wholeSalePrice}
            maxLength={10}
            onChange={(e) => {
              if (!validateRegex('number', e.target.value)) {
                return;
              }
              setEditable({
                ...editable,
                wholeSalePrice: e.target.value,
              });
            }}
          />
        </div>

        <div
          className="pt-4"
        >
          <p
            className="font-bold text-sm"
          >
            Minimum Order Quantity
          </p>
          <input
            className={`mt-2 border border-gray ${style.textInputBox}`}
            type="text"
            name="minimumOrderQuantity"
            value={editable.minimumOrderQuantity}
            maxLength={10}
            onChange={(e) => {
              if (!validateRegex('number', e.target.value)) {
                return;
              }
              setEditable({
                ...editable,
                minimumOrderQuantity: e.target.value,
              });
            }}
          />
        </div>

        <div
          className="pt-4"
        >
          <button
            type="button"
            className={`text-white ${style.saveButton} ${isSaveButtonDisabled ? 'bg-gray' : 'bg-blue'}`}
            onClick={() => {
              patchProduct(product, editable);
              onHide();
            }}
            disabled={isSaveButtonDisabled}
          >
            SAVE
          </button>
        </div>
      </div>
    </Modal>
  );
}

ProductEdit.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    minimumOrderQuantity: PropTypes.number,
    retailPrice: PropTypes.number,
    wholeSalePrice: PropTypes.number,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  patchProduct: PropTypes.func.isRequired,
};

export default ProductEdit;
