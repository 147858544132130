import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

function OrderTab(props) {
  const { onSelectTab, selectedTab } = props;
  return (
    <div
      className={`bg-white ${style.orderTab}`}
    >
      {
        [
          {
            id: 'PENDING',
            displayName: 'New',
            isSelected: selectedTab === 'PENDING',
          },
          {
            id: 'IN_PROGRESS',
            displayName: 'In Progress',
            isSelected: selectedTab === 'IN_PROGRESS',
          },
          {
            id: 'CLOSED',
            displayName: 'Delivered',
            isSelected: selectedTab === 'CLOSED',
          },
        ].map((item) => (
          <button
            type="button"
            key={item.id}
            className={`${style.tabBox} ${item.isSelected ? 'border-b-2 border-blue' : ''}`}
            onClick={() => {
              onSelectTab(item.id);
            }}
          >
            <div
              className={`py-2 ${item.isSelected ? 'text-blue' : ''}`}
            >
              {item.displayName}
            </div>
          </button>
        ))
      }
    </div>
  );
}

OrderTab.defaultProp = {
  onSelectTab: () => {},
};

OrderTab.propTypes = {
  onSelectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
};

export default OrderTab;
