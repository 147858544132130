import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import brandApis from '../../apis/brand';
import CircularSpinner from '../../components/loader';

function Home() {
  const navigate = useNavigate();
  const [brandDetails, setBrandDetails] = useState({
    loading: true,
    error: false,
    data: null,
  });

  const getBrandDetails = () => {
    brandApis.brandDetails().then((res) => {
      setBrandDetails({
        ...brandDetails,
        loading: false,
        error: false,
        data: res.data.data,
      });
    }).catch(() => {
      setBrandDetails({
        loading: false,
        error: true,
        data: null,
      });
    });
  };

  useEffect(() => {
    getBrandDetails();
  }, []);

  const brandDetailsData = brandDetails.data;

  return (
    <div
      className="mx-3"
    >
      {
        (() => {
          let brandDetailsJSX = null;
          if (brandDetails.loading) {
            brandDetailsJSX = (
              <div
                className="flex justify-center"
              >
                <CircularSpinner />
              </div>
            );
          } else if (brandDetails.error) {
            brandDetailsJSX = (
              <div
                className="flex justify-center text-red p-4"
              >
                Oops Something Went Wrong
              </div>
            );
          } else if (brandDetailsData) {
            brandDetailsJSX = (
              <>
                <div
                  className={`bg-white py-4 px-4 mt-4 ${style.borderRadius16}`}
                >
                  <div
                    className="text-xl font-extrabold"
                  >
                    Welcome To
                    <span
                      className="text-blue"
                    >
                      &nbsp;
                      {brandDetailsData.businessName}
                    </span>
                  </div>

                  <div
                    className="mt-3 text-md"
                  >
                    {brandDetailsData.description}
                  </div>

                  <div
                    className="mt-3"
                  >
                    Contact -&nbsp;
                    <a
                      className="text-blue"
                      href={`tel:+91${brandDetailsData.phoneNumber}`}
                    >
                      {brandDetailsData.phoneNumber}
                    </a>
                  </div>
                  <div
                    className="mt-3"
                  >
                    Min Order Amt -&nbsp;
                    {brandDetailsData.minOrderAmount}
                  </div>

                  <div
                    className="mt-3"
                  >
                    Active -&nbsp;
                    <b
                      className={`${brandDetailsData.isActive ? 'text-green' : 'text-red'}`}
                    >
                      {brandDetailsData.isActive ? 'YES' : 'NO'}
                    </b>
                  </div>

                  <div
                    className="mt-3"
                  >
                    Address -&nbsp;
                    {brandDetailsData.address}
                  </div>
                </div>
                <div
                  className={`bg-white py-4 px-4 mt-4 ${style.borderRadius16}`}
                >
                  <div
                    className="flex justify-center"
                  >
                    <button
                      type="button"
                      className={`${style.borderRadius16} w-3/4 py-3 bg-green text-white text-lg font-extrabold`}
                      onClick={() => {
                        navigate('/products');
                      }}
                    >
                      SEE ALL PRODUCTS
                    </button>
                  </div>
                  <div
                    className="mt-3 flex justify-center"
                  >
                    <button
                      type="button"
                      className={`${style.borderRadius16} w-3/4 py-3 bg-green text-white text-lg font-extrabold`}
                      onClick={() => {
                        navigate('/orders');
                      }}
                    >
                      SEE ALL ORDERS
                    </button>
                  </div>
                  <div
                    className="mt-3 flex justify-center"
                  >
                    <button
                      type="button"
                      className={`${style.borderRadius16} w-3/4 py-3 bg-green text-white text-lg font-extrabold`}
                      onClick={() => {
                        navigate('/brands');
                      }}
                    >
                      SEE ALL BRANDS
                    </button>
                  </div>
                </div>
              </>
            );
          }
          return brandDetailsJSX;
        })()
      }
    </div>
  );
}

export default Home;
