import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

// type sizeType = 'sm' | 'md' | 'lg';

function Modal(props) {
  const {
    show, onHide, size, children, backdropStatic,
  } = props;
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      if (modalRef.current !== null) {
        modalRef.current.classList.add(style.visible);
      }
    } else if (modalRef.current !== null) {
      modalRef.current.classList.remove(style.visible);
    }
  }, [show]);

  const handleBackdrop = () => {
    if (!backdropStatic) {
      onHide();
    }
  };

  return (
    <div className={` ${style.modalMain} `} ref={modalRef}>
      <div
        className={style.backdrop}
        onClick={handleBackdrop}
        onKeyDown={handleBackdrop}
        role="button"
        tabIndex={0}
        aria-labelledby="button-label"
      />
      <div className={` ${style.modalBody} ${style[size]}`}>
        {children}
      </div>

    </div>
  );
}

Modal.defaultProps = {
  size: 'md',
  onHide: () => {},
  backdropStatic: false,
};

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  children: PropTypes.element.isRequired,
  size: PropTypes.string,
  backdropStatic: PropTypes.bool,
};

export default Modal;
