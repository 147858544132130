export const getCurrentStatus = (state, action, value) => ({
  ...state, [action]: value,
});

export const getCurrentStatusOnReset = (requests, state) => {
  const newState = { ...state };
  requests.forEach((request) => {
    newState[request] = false;
  });
  return newState;
};

export const middlewareRequestHandler = (next, handler, actionHandler, data, isAborted) => {
  if (isAborted) {
    return;
  }
  if (handler) {
    if (handler.type === 'sync') {
      handler.callback(data, () => {
        next(actionHandler(data));
      });
    } else {
      handler.callback(data);
      next(actionHandler(data));
    }
  } else {
    next(actionHandler(data));
  }
};
