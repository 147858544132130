import { useEffect, useState } from 'react';

/*
  Use Only in Functional Component
  Because it uses useState & useEffect Hooks
*/

const useResponsiveBreakpoints = () => {
  const [windowWidth, setWindowWidth] = useState(null);
  const [windowHeight, setWindowHeight] = useState(null);
  useEffect(() => {
    setWindowWidth(window.innerWidth);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return { windowWidth, windowHeight };
};

export default useResponsiveBreakpoints;
