import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import style from './index.module.scss';
import { formatDateToTodayTomorrow, getAddressString, getInitials } from '../../../utilities/utils';

function OrderCard(props) {
  const { order, isOrderDetails } = props;

  const phoneNumber = 912248904547;

  return (
    <Link
      to={`/orders/${order.orderId}`}
      className={`bg-white mb-4 mx-2 ${isOrderDetails ? style.orderDetailsCard : style.orderCard}`}
      aria-disabled={isOrderDetails}
    >
      <div
        className={`${style.profileInitBox} bg-blue-light text-white font-extrabold text-3xl`}
      >
        {getInitials(order.store.name)}
      </div>

      <div
        className={`ml-4 flex ${style.orderInfoSection}`}
      >
        <div
          className="w-2/3 text-sm font-light"
        >
          <div>
            {order.store.name}
          </div>
          <div>
            {phoneNumber}
          </div>
          <div>
            <span>
              {order.productCount}
              &nbsp;
              Items
            </span>
            <span>
              &nbsp;·
              ₹
              {order.payment.amountPayable}
              &nbsp;
            </span>
            <span>
              ·&nbsp;
              {order.payment.mode}
            </span>
          </div>
          <div>
            <span>
              #
              {order.orderId}
            </span>
            <span>
              &nbsp;·&nbsp;
              Delivery
            </span>
          </div>
          {
          order.deliveryAddress && (
            <div
              className="text-sm pt-1"
            >
              <b
                className="font-bold"
              >
                Address -
              </b>
              &nbsp;
              {getAddressString(order.deliveryAddress)}
            </div>
          )
        }
        </div>
        <div
          className="w-1/3 text-sm text-right"
        >
          {/* Date & state */}
          <div>
            {formatDateToTodayTomorrow(order.createdAt)}
          </div>
          <div
            className={`text-md font-bold ${
              order.brand_status.identifier === 'CANCELLED' && 'text-red'
            }
            ${
              order.brand_status.identifier === 'DELIVERED' && 'text-green'
            }
            `}
          >
            {order.brand_status.identifier}
          </div>
        </div>
      </div>
    </Link>
  );
}

OrderCard.defaultProps = {
  isOrderDetails: false,
};

OrderCard.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.number,
    productCount: PropTypes.number,
    createdAt: PropTypes.string,
    brand_status: PropTypes.shape({
      identifier: PropTypes.string,
    }),
    payment: PropTypes.shape({
      mode: PropTypes.string,
      amountPayable: PropTypes.number,
    }),
    store: PropTypes.shape({
      phoneNumber: PropTypes.string,
      name: PropTypes.string,
    }),
    deliveryAddress: PropTypes.shape({}),
  }).isRequired,
  isOrderDetails: PropTypes.bool,
};

export default OrderCard;
