import Axios from 'axios';
import {
  apiUrls, setHeaders,
} from '../utils';

const { newOOBaseUrl } = apiUrls();

const authApis = {

  brandOtp: (cancelToken, data = {}) => {
    const brandLogin = `${newOOBaseUrl}auth/send-otp`;
    return Axios({
      url: brandLogin,
      method: 'POST',
      headers: setHeaders(),
      data,
      cancelToken,
    });
  },

  brandLogin: (cancelToken, data = {}) => {
    const brandLogin = `${newOOBaseUrl}auth/login`;
    return Axios({
      url: brandLogin,
      method: 'POST',
      headers: setHeaders(),
      data,
      cancelToken,
    });
  },

  refreshToken: (cancelToken, data = {}) => {
    const refreshToken = `${newOOBaseUrl}auth/refresh-token`;
    return Axios({
      url: refreshToken,
      method: 'POST',
      headers: setHeaders(),
      data,
      cancelToken,
    });
  },
};

export default authApis;
