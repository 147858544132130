import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

function ProductDetails(props) {
  const { history } = props;
  console.log(props, history);
  return (
    <div
      className={style.homeDiv}
    >
      ProductDetails
    </div>
  );
}

ProductDetails.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default ProductDetails;
