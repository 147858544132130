import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

function Footer(props) {
  const { isMobile, page } = props;
  console.log(isMobile, page);
  return (
    <div
      className={`bg-black ${style.footerBody}`}
    >
      <b
        className="text-white"
      >
        ABCD
      </b>
    </div>
  );
}

Footer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired,
};

export default Footer;
