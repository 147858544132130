import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';
import Switch from '../../../components/switch';
import ProductEdit from '../product-edit';

function ProductCard(props) {
  const { product, patchProduct } = props;
  const [showEdit, setShowEdit] = useState(false);

  return (
    <>
      <ProductEdit
        show={showEdit}
        onHide={() => {
          setShowEdit(false);
        }}
        product={product}
        patchProduct={patchProduct}
      />
      <div
        className={`bg-white mb-3 mx-2 px-3 py-2 flex ${style.productCard}`}
      >
        <div
          className={style.width80p}
        >
          <div
            className={`border border-gray-lighter flex items-center justify-center ${style.productImage}`}
          >
            <img
              src={product.imageUrl}
              alt={product.name}
              width="100%"
              height="100%"
            />
          </div>
          <div>
            <button
              type="button"
              onClick={() => {
                setShowEdit(true);
              }}
              className={`border border-blue text-blue text-sm font-extrabold ${style.editButton}`}
            >
              EDIT
            </button>
          </div>
        </div>
        <div
          className="pl-4"
        >
          <div>
            <b
              className="text-blue"
            >
              {product.brandName}
            </b>
          </div>
          <b>
            {product.name}
          </b>
          <p>
            Retail Price:&nbsp;
            ₹
            {product.retailPrice}
          </p>
          <p>
            whole Sale Price:&nbsp;
            ₹
            {product.wholeSalePrice}
          </p>
          <p>
            Min. Order Qty:&nbsp;
            {product.minimumOrderQuantity}
          </p>
          <p>
            {product.measurementValue}
          &nbsp;
            {product.measurementUnit}
          </p>
          <p>
            In Stock:
            <span>
              <Switch
                id={`${product.id}`}
                displayTextLeft=""
                displayTextRight=""
                onChange={(e) => {
                  patchProduct(product, {
                    inStock: e.target.checked,
                  });
                }}
                checked={product.inStock}
              />
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    minimumOrderQuantity: PropTypes.number,
    imageUrl: PropTypes.string,
    brandName: PropTypes.string,
    retailPrice: PropTypes.number,
    wholeSalePrice: PropTypes.number,
    measurementValue: PropTypes.string,
    measurementUnit: PropTypes.string,
    inStock: PropTypes.bool,
  }).isRequired,
  patchProduct: PropTypes.func.isRequired,
};

export default ProductCard;
