import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import ProductCard from './product-card';
import productApis from '../../apis/product';
import CircularSpinner from '../../components/loader';

function Products() {
  const [products, setProducts] = useState({
    loading: true,
    error: false,
    data: null,
  });

  const getProducts = () => {
    productApis.productList().then((res) => {
      setProducts({
        ...products,
        loading: false,
        error: false,
        data: res.data,
      });
    }).catch(() => {
      setProducts({
        loading: false,
        error: true,
        data: null,
      });
    });
  };

  const patchProduct = (product, patchData = {}) => {
    productApis.productDetailsPatch(
      null,
      {},
      product.id,
      patchData,
    ).then(() => {
      const updatedProducts = {
        ...products,
        data: {
          ...products.data,
          data: products.data.data.map((item) => (
            item.id === product.id
              ? {
                ...item,
                ...patchData,
              }
              : item
          )),
        },
      };
      setProducts(updatedProducts);
    }).catch(() => {});
  };

  useEffect(() => {
    getProducts();
  }, []);

  const productsData = products.data;

  return (
    <div
      className={style.productContainer}
    >
      {
        (() => {
          let productsJSX = null;
          if (products.loading) {
            productsJSX = (
              <div
                className="py-4 flex justify-center"
              >
                <CircularSpinner />
              </div>
            );
          } else if (products.error) {
            productsJSX = (
              <div
                className="py-4 flex justify-center"
              >
                Oops Something Went Wrong
              </div>
            );
          } else if (productsData) {
            productsJSX = (
              <div
                className={style.productBody}
              >
                <div
                  className={` mt-4 flex justify-center ${style.productSection}`}
                >
                  {/* Product Cards */}
                  {
                    productsData.data.map((item) => (
                      <ProductCard
                        key={item.id}
                        product={item}
                        patchProduct={patchProduct}
                      />
                    ))
                  }
                </div>
              </div>
            );
          }
          return productsJSX;
        })()
      }
    </div>
  );
}

Products.propTypes = {
  //
};

export default Products;
