import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useResponsiveBreakpoints from '../utilities/useResponsiveBreakpoints';
import style from './index.module.scss';
import Footer from './footer';
import Header from './header';
import {
  isFullyAuthenticated, isLoggedIn, updateAccessTokenAndRefreshToken,
} from '../utilities/authenticate';
import Support from './support';

function Wrapper(props) {
  const {
    Component, page, isFullWidth,
    isHeader, isFooter,
  } = props;
  const { windowWidth } = useResponsiveBreakpoints();
  const isMobile = windowWidth <= 767;

  useEffect(() => {
    if (isFullyAuthenticated() || isLoggedIn()) {
      updateAccessTokenAndRefreshToken();
    }
  }, []);

  const isBypassAuthentication = (
    page === 'Auth'
    || page === 'About'
    || page === 'T&C'
  );

  if (
    !isBypassAuthentication
    && !isFullyAuthenticated()
  ) {
    return (
      <Navigate to="/login" />
    );
  }
  return (
    <div>
      {/* Header */}
      {
        isHeader && (
          <div>
            <Header
              isMobile={isMobile}
              page={page}
            />
          </div>
        )
      }

      <div
        className={
          isHeader
            ? style.wrapperWithHeaderContainer
            : style.wrapperWithoutHeaderContainer
        }
      >
        {/* Main Page Components */}
        <div
          className={style.mainPageBody}
        >
          <div
            className={`mb-20 ${isFullWidth ? style.wrapperFullWidthBody : style.wrapperDefaultBody}`}
          >
            <Component
              isMobile={isMobile}
              page={page}
            />
          </div>
        </div>

        {/* Footer */}
        {
          isFooter && (
            <div>
              <Footer
                isMobile={isMobile}
                page={page}
              />
            </div>
          )
        }
      </div>

      <div>
        <Support />
      </div>
    </div>
  );
}

Wrapper.defaultProps = {
  isFullWidth: false,
  isHeader: true,
  isFooter: true,
};

Wrapper.propTypes = {
  Component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  page: PropTypes.string.isRequired,
  isFullWidth: PropTypes.bool,
  isHeader: PropTypes.bool,
  isFooter: PropTypes.bool,
};

export default Wrapper;
