/* eslint-disable default-param-last */
import { getCurrentStatus } from '../utils';
import { brandLoginAction } from './actions';

const initState = {
  requestsProcessing: {
    [brandLoginAction.types.BRAND_LOGIN_REQUEST]: false,
  },
  requestsFailure: {
    [brandLoginAction.types.BRAND_LOGIN_REQUEST]: false,
  },
  brands: null,
  selectedBrand: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    // Login Requests
    case brandLoginAction.types.BRAND_LOGIN_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          brandLoginAction.types.BRAND_LOGIN_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          brandLoginAction.types.BRAND_LOGIN_REQUEST,
          true,
        ),
      });
    }

    case brandLoginAction.types.BRAND_LOGIN_SUCCESS: {
      const { brands } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          brandLoginAction.types.BRAND_LOGIN_REQUEST,
          false,
        ),
        brands,
      });
    }

    case brandLoginAction.types.BRAND_LOGIN_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          brandLoginAction.types.BRAND_LOGIN_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          brandLoginAction.types.BRAND_LOGIN_REQUEST,
          true,
        ),
      });
    }

    default:
      return state;
  }
};
