import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import style from './index.module.scss';
import LoveLocalIcon from '../../components/svg/LoveLocalIcon';
import authApis from '../../apis/auth';
import {
  setAccessToken, setBrandUserId, setBrandUser,
  setBrands, setRefreshToken, setLastAccessTokenUpdatedAtEpoch,
} from '../../utilities/storage';
import { getSOAErrorText, validateRegex } from '../../utilities/utils';
import CircularSpinner from '../../components/loader';
import {
  ACCESS_TOKEN_TTL, isFullyAuthenticated, isLoggedIn, updateAccessTokenAndRefreshToken,
} from '../../utilities/authenticate';
import Brands from '../brands';

function Auth() {
  const [stage, setStage] = useState('PHONE');
  const [otp, setOtp] = useState('');
  const [phone, setPhone] = useState('');
  const [submitState, setSubmitState] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [otpResendTimer, setOtpResendTimer] = useState(0);

  useEffect(() => {
    let interval;

    if (otpResendTimer > 0) {
      interval = setInterval(() => {
        setOtpResendTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [otpResendTimer]);

  const isSubmitButtonDisabled = (
    (submitState === 'loading')
      || (
        stage === 'OTP'
      && (
        !(otp.length === 4)
      )) || (
      stage === 'PHONE'
        && (
          (!(phone.length === 10))
        ))
  );

  const setOtpTimer = () => {
    setOtpResendTimer(30);
  };

  const submitOTP = () => {
    setSubmitState('loading');
    setSubmitError('');
    authApis.brandLogin(null, {
      phone_number: phone,
      role: 'BRANDUSER',
      otp,
    }).then((res) => {
      const brand = res.data.data;
      setAccessToken(brand.access_token);
      setRefreshToken(brand.refresh_token);
      setBrands(brand.brands);
      setBrandUserId(brand.brandUser.id);
      setBrandUser(brand.brandUser);
      setLastAccessTokenUpdatedAtEpoch(new Date().getTime());
      setTimeout(() => {
        updateAccessTokenAndRefreshToken();
      }, (new Date().getTime()) + (ACCESS_TOKEN_TTL));
      setSubmitState('');
      setStage('BRANDS');
    }).catch((error) => {
      const errorMsg = getSOAErrorText(error) || 'Oops Something Went Wrong!';
      setSubmitState('error');
      setSubmitError(errorMsg);
    });
  };

  const submitPhone = () => {
    setSubmitState('loading');
    setSubmitError('');
    authApis.brandOtp(null, {
      phone_number: phone,
      role: 'BRANDUSER',
    }).then(() => {
      setStage('OTP');
      setSubmitState('');
      setOtpTimer();
    }).catch((error) => {
      const errorMsg = getSOAErrorText(error) || 'Oops Something Went Wrong!';
      setSubmitState('error');
      setSubmitError(errorMsg);
    });
  };

  if (isFullyAuthenticated()) {
    return (
      <Navigate to="/" />
    );
  }
  return (
    stage === 'BRANDS'
    || isLoggedIn()
  ) ? (
    <Brands />
    ) : (
      <div
        className={`flex justify-center bg-blue ${style.authContainer}`}
      >
        <div
          className={`${style.authBody}`}
        >
          <div
            className="pt-8 flex justify-center"
          >
            <LoveLocalIcon />
          </div>
          <h1
            className="pt-4 text-center text-white font-bold text-2xl"
          >
            Marketplace
          </h1>

          <div
            className="px-8 pt-6"
          >
            <p
              className="text-white font-extralight text-sm"
            >
              Phone Number
            </p>
            <input
              className={`mt-2 ${style.textInputBox}`}
              type="text"
              name="phone"
              value={phone}
              maxLength={10}
              disabled={stage === 'OTP'}
              onChange={(e) => {
                if (!validateRegex('phoneNumber', e.target.value)) {
                  return;
                }
                setPhone(e.target.value);
              }}
            />
            {
              stage === 'OTP' && (
                <div
                  className="pt-1 flex items-center justify-end"
                >
                  {
                    otpResendTimer < 1 ? (
                      <button
                        type="button"
                        className="text-sm text-white font-bold"
                        disabled={submitState === 'loading'}
                        onClick={() => {
                          submitPhone();
                        }}
                      >
                        RESEND OTP
                      </button>
                    ) : (
                      <div
                        className="text-sm text-white font-bold"
                      >
                        {`Resend OTP in (00:${
                          otpResendTimer < 10 ? `0${otpResendTimer}` : otpResendTimer
                        })`}
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>

          {
            stage === 'OTP' && (
              <div
                className="px-8 pt-6"
              >
                <p
                  className="text-white font-extralight text-sm"
                >
                  OTP
                </p>
                <input
                  className={`mt-2 ${style.textInputBox}`}
                  type="password"
                  name="otp"
                  value={otp}
                  maxLength={4}
                  onChange={(e) => {
                    if (!validateRegex('otp', e.target.value)) {
                      return;
                    }
                    setOtp(e.target.value);
                  }}
                />
              </div>
            )
          }

          <div
            className="px-8 pt-6 pb-6"
          >
            <button
              type="button"
              className={`text-white font-bold flex items-center justify-center ${
                style.loginButton
              } ${
                isSubmitButtonDisabled ? 'bg-gray' : 'bg-green'
              }`}
              disabled={isSubmitButtonDisabled}
              onClick={() => {
                if (stage === 'OTP') {
                  submitOTP();
                } else {
                  submitPhone();
                }
              }}
            >
              {
                submitState === 'loading' && (
                  <>
                    <CircularSpinner
                      width={16}
                      height={16}
                    />
                    &nbsp;&nbsp;
                  </>
                )
              }
              {stage === 'OTP' ? 'SUBMIT OTP' : 'GET OTP'}
            </button>
          </div>

          {
            submitError && (
              <div
                className="px-8 text-red text-sm"
              >
                {submitError}
              </div>
            )
          }
        </div>
      </div>
    );
}

export default Auth;
