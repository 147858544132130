// Auth Actions
const BRAND_LOGIN_REQUEST = 'STORE_OFFER_LOAD_REQUEST';
const BRAND_LOGIN_SUCCESS = 'STORE_OFFER_LOAD_SUCCESS';
const BRAND_LOGIN_FAILURE = 'STORE_OFFER_LOAD_FAILURE';

export const brandLoginAction = {
  types: {
    BRAND_LOGIN_REQUEST,
    BRAND_LOGIN_SUCCESS,
    BRAND_LOGIN_FAILURE,
  },
  request: {
    load: (onSuccess, onFailure, storeCode, params) => ({
      type: BRAND_LOGIN_REQUEST,
      payload: {
        onSuccess,
        onFailure,
        storeCode,
        params,
      },
    }),
    success: (data) => ({
      type: BRAND_LOGIN_SUCCESS,
      payload: {
        storeOffers: data.res,
      },
    }),
    failure: () => ({
      type: BRAND_LOGIN_FAILURE,
    }),
  },
};

export const dummy = {};
