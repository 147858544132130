import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Auth from './pages/auth';
import Home from './pages/home';
import OrderDetails from './pages/order-details';
import Orders from './pages/orders';
import Products from './pages/products';
import ProductDetails from './pages/product-details';
import Brands from './pages/brands';
import Wrapper from './wrapper';

const router = createBrowserRouter([
  {
    path: '/login',
    loader: async (loaderData) => loaderData,
    Component() {
      return (
        <Wrapper
          Component={Auth}
          page="Auth"
          isFullWidth
          isHeader={false}
          isFooter={false}
        />
      );
    },
  },
  {
    path: '/brands',
    loader: async (loaderData) => loaderData,
    Component() {
      return (
        <Wrapper
          Component={Brands}
          page="Brands"
          // isFullWidth
          // isHeader={false}
          // isFooter={false}
        />
      );
    },
  },
  {
    path: '/orders',
    loader: async (loaderData) => loaderData,
    Component() {
      return (
        <Wrapper
          Component={Orders}
          page="Orders"
          isFullWidth
        />
      );
    },
  },
  {
    path: 'orders/:orderId',
    loader: async (loaderData) => loaderData,
    Component() {
      return (
        <Wrapper
          Component={OrderDetails}
          page="OrderDetails"
        />
      );
    },
  },
  {
    path: '/products',
    loader: async (loaderData) => loaderData,
    Component() {
      return (
        <Wrapper
          Component={Products}
          page="Products"
        />
      );
    },
  },
  {
    path: 'products/:productId',
    loader: async (loaderData) => loaderData,
    Component() {
      return (
        <Wrapper
          Component={ProductDetails}
          page="ProductDetails"
        />
      );
    },
  },
  {
    path: '/',
    loader: async (loaderData) => loaderData,
    Component() {
      return (
        <Wrapper
          Component={Home}
          page="Home"
        />
      );
    },
  },
]);

export default router;
