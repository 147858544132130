import Axios from 'axios';
import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from '../utils';

const { newOOBaseUrl } = apiUrls();

const orderApis = {
  orderList: (orderStage, brandId, cancelToken) => {
    const orderList = `${newOOBaseUrl}brand-order/orders`;
    return Axios({
      url: orderList,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams({
        orderStage,
        brandId,
      }),
      cancelToken,
    });
  },

  orderDetails: (orderId, brandId, cancelToken) => {
    const orderDetails = `${newOOBaseUrl}brand-order/${orderId || ''}`;
    return Axios({
      url: orderDetails,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams({
        brandId,
      }),
      cancelToken,
    });
  },

  orderDetailsPatch: (orderId, data, cancelToken) => {
    const orderDetails = `${newOOBaseUrl}brand-order/${orderId || ''}`;
    return Axios({
      url: orderDetails,
      method: 'PATCH',
      headers: setHeaders(setAuthorizationHeaders()),
      data,
      params: getParams({}),
      cancelToken,
    });
  },
};

export default orderApis;
