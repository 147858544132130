import Axios from 'axios';
import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from '../utils';
import { getBrandId } from '../../utilities/storage';

const { newOOBaseUrl } = apiUrls();

const brandId = getBrandId();

const brandApis = {
  brandList: (cancelToken, param = {}) => {
    const brandList = `${newOOBaseUrl}brand`;
    return Axios({
      url: brandList,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams(param),
      cancelToken,
    });
  },

  brandDetails: (cancelToken, param = {}) => {
    const brandDetails = `${newOOBaseUrl}brand/${brandId || ''}`;
    return Axios({
      url: brandDetails,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams(param),
      cancelToken,
    });
  },

  brandDetailsPatch: (cancelToken, param = {}, data = {}) => {
    const brandDetailsPatch = `${newOOBaseUrl}brand/${brandId || ''}`;
    return Axios({
      url: brandDetailsPatch,
      method: 'PATCH',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams(param),
      data,
      cancelToken,
    });
  },
};

export default brandApis;
