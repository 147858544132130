import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

function OrderProducts(props) {
  const { product } = props;
  return (
    <div
      className="pb-2 flex justify-between"
    >
      <div
        className="flex items-center"
      >
        <div
          className={`flex justify-center ${style.productImage}`}
        >
          <img
            src={product.image}
            alt={product.name}
            width="100%"
            height="100%"
          />
        </div>
        <div
          className="px-3"
        >
          {product.name}
        </div>
      </div>
      <div>
        <div>
          QTY:&nbsp;
          {product.quantity}
        </div>
        <div
          className="font-bold"
        >
          ₹
          {product.totalPrice}
        </div>
      </div>
    </div>
  );
}

OrderProducts.propTypes = {
  product: PropTypes.shape({
    totalPrice: PropTypes.number,
    quantity: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.string,
  }).isRequired,
};

export default OrderProducts;
