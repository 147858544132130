import authApis from '../../apis/auth';
import { middlewareRequestHandler } from '../utils';
import { brandLoginAction } from './actions';

const { brandLogin } = authApis;

const profileMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  let onSuccess;
  let onFailure;
  let cancelToken;
  if (action.payload) {
    onSuccess = action.payload.onSuccess;
    onFailure = action.payload.onFailure;
    cancelToken = action.payload.cancelToken;
  }
  console.log(state);

  switch (action.type) {
    case brandLoginAction.types.BRAND_LOGIN_REQUEST: {
      const { postData } = action.payload;
      next(action);
      brandLogin(cancelToken, postData)
        .then((res) => {
          const data = {
            res: res.data,
          };
          middlewareRequestHandler(
            next,
            onSuccess,
            brandLoginAction.request.success,
            data,
          );
        })
        .catch(() => {
          middlewareRequestHandler(
            next,
            onFailure,
            brandLoginAction.request.failure,
          );
        });
      break;
    }

    default:
      break;
  }
};

export default profileMiddleware;
