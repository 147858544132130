/* eslint-disable no-useless-escape */
const Regex = {
  number: '[0-9]',
  phoneNumber: '[0-9]',
  otp: '[0-9]',
  pinCode: '[0-9]',
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};

export default Regex;
