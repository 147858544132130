import Regex from './regex';

const joinText = (textElement, withDelimeter = true) => {
  const text = textElement ? textElement.trim() : '';
  if (text === '') {
    return '';
  }
  return `${text}${
    withDelimeter ? ', ' : ''
  }`;
};

export const getInitials = (text) => {
  const initials = text.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const getAddressString = (address = {}) => {
  if (address) {
    const {
      flatNumber, buildingName, streetName,
      localityName, landmark, addressText, googleAddress,
    } = address;
    return `${
      joinText(flatNumber)
    }${
      joinText(buildingName)
    }${
      joinText(streetName)
    }${
      joinText(localityName)
    }${
      joinText(landmark)
    }${
      addressText
        ? joinText(addressText, false)
        : joinText(googleAddress, false)
    }`;
  }
  return address ? address.addressText : '';
};

// export const debounce = (func, timeout = 300) => {
//   let timeoutId;

//   return function (...args) {
//     const context = this;

//     // Clear the previous timeout (if any) to avoid invoking the function prematurely.
//     clearTimeout(timeoutId);

//     // Set up a new timeout to invoke the function after the specified delay.
//     timeoutId = setTimeout(() => {
//       func.apply(context, args);
//     }, timeout);
//   };
// };

export const validateRegex = (field, value) => {
  switch (field) {
    case 'number': {
      const regex = new RegExp(`^${Regex[field]}{0,10}$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    case 'phoneNumber': {
      const regex = new RegExp(`^${Regex[field]}{0,10}$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    case 'otp': {
      const regex = new RegExp(`^${Regex[field]}{0,4}$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    case 'pinCode': {
      const regex = new RegExp(`^${Regex[field]}{0,6}$`);
      if (value.toString().match(regex)) {
        return true;
      }
      return false;
    }
    default: return true;
  }
};

export const formatDateToTodayTomorrow = (date) => {
  const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  const dateOptions = {
    weekday: 'short', month: 'short', day: 'numeric', ...timeOptions,
  };
  // Get the current date and tomorrow's date
  const currentDate = new Date();
  const tomorrowDate = new Date();
  const yesterdayDate = new Date();
  tomorrowDate.setDate(currentDate.getDate() + 1);
  yesterdayDate.setDate(currentDate.getDate() - 1);

  // Convert all dates to UTC to ignore time zone differences
  currentDate.setUTCHours(0, 0, 0, 0);
  tomorrowDate.setUTCHours(0, 0, 0, 0);
  yesterdayDate.setUTCHours(0, 0, 0, 0);

  // Convert the input date to UTC
  const inputDate = new Date(date);
  inputDate.setUTCHours(0, 0, 0, 0);

  // Compare the input date with current and tomorrow's date
  if (inputDate.getTime() === currentDate.getTime()) {
    return `Today, ${new Date(date).toLocaleTimeString('en-IN', timeOptions)}`;
  }
  if (inputDate.getTime() === tomorrowDate.getTime()) {
    return `Tomorrow, ${new Date(date).toLocaleTimeString('en-IN', timeOptions)}`;
  }
  if (inputDate.getTime() === yesterdayDate.getTime()) {
    return `Yesterday, ${new Date(date).toLocaleTimeString('en-IN', timeOptions)}`;
  }

  // For any other date, return the original date in a formatted string
  return new Date(date).toLocaleDateString('en-IN', dateOptions);
};

export const getSOAErrorText = (error) => {
  let errorMsg = '';
  if (
    error
      && error.response
      && error.response.data
      && error.response.data.errors
      && error.response.data.errors[0].message
  ) {
    errorMsg = error.response.data.errors[0].message;
  }
  return errorMsg;
};
