import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

function Switch(props) {
  const {
    displayTextLeft, displayTextRight, checked, onChange, id, disabled,
  } = props;
  return (
    <>
      <label
        htmlFor={id}
        className={displayTextLeft === '' ? 'hidden' : `px-2 m-0 ${checked ? '' : 'font-extrabold'}`}
      >
        {displayTextLeft}
      </label>
      <input
        type="checkbox"
        id={id}
        name="set-name"
        onChange={onChange}
        className={style.switchInput}
        checked={checked}
        disabled={disabled}
      />
      <label
        htmlFor={id}
        className={`${style.switchLabel} m-0 ${checked ? 'font-extrabold' : ''} ${
          disabled ? '' : 'cursor-pointer'
        }`}
      >
        {displayTextRight}
      </label>
    </>
  );
}

Switch.defaultProps = {
  displayTextLeft: '',
  displayTextRight: '',
  checked: false,
  disabled: false,
};

Switch.propTypes = {
  displayTextLeft: PropTypes.string,
  displayTextRight: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Switch;
