import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './index.module.scss';
import LoveLocalIcon from '../../components/svg/LoveLocalIcon';
import { logoutUser } from '../../utilities/authenticate';

function Header() {
  const navigate = useNavigate();
  return (
    <div
      className={`bg-blue flex items-center justify-between px-6 ${style.headerBody}`}
    >
      <div>
        <button
          type="button"
          onClick={() => {
            navigate('/');
          }}
        >
          <LoveLocalIcon
            width={48}
            height={40}
          />
        </button>
      </div>
      <div>
        <button
          type="button"
          className="text-white text-md font-black"
          onClick={() => {
            logoutUser();
          }}
        >
          LOGOUT
        </button>
      </div>
    </div>
  );
}

export default Header;
