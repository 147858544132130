/* eslint-disable camelcase */
import authApis from '../apis/auth';
import {
  getAccessToken, getBrandId, getBrandUser, getBrandUserId, getBrands,
  getLastAccessTokenUpdatedAtEpoch, getRefreshToken, removeAuthentication,
  setAccessToken, setLastAccessTokenUpdatedAtEpoch, setRefreshToken,
} from './storage';

export const ACCESS_TOKEN_TTL = 23 * 60 * 60 * 1000;

const allBrands = getBrands();
const brandUser = getBrandUser();
const accessToken = getAccessToken();
const refreshToken = getRefreshToken();
const brandUserId = getBrandUserId();
const brandId = getBrandId();

export const isLoggedIn = () => {
  if (
    allBrands
    && brandUser
    && accessToken
    && refreshToken
    && brandUserId
  ) {
    return true;
  }
  return false;
};

export const isFullyAuthenticated = () => {
  if (
    allBrands
    && brandUser
    && accessToken
    && refreshToken
    && brandUserId
    && brandId
  ) {
    return true;
  }
  return false;
};

export const logoutUser = () => {
  removeAuthentication();
  window.location.reload();
};

export const updateAccessTokenAndRefreshToken = (retryCount = 3) => {
  const lastAccessTokenUpdatedAtEpoch = getLastAccessTokenUpdatedAtEpoch();
  if (
    !!Number(lastAccessTokenUpdatedAtEpoch)
    && ((new Date()).getTime() - Number(lastAccessTokenUpdatedAtEpoch)) > (ACCESS_TOKEN_TTL)
  ) {
    const refresh_token = getRefreshToken() || '';
    const access_token = getAccessToken() || '';
    authApis.refreshToken(
      null,
      {
        refresh_token,
        access_token,
        role: 'BRANDUSER',
      },
    ).then((res) => {
      const tokens = res.data;
      setAccessToken(tokens.data.access_token);
      setRefreshToken(tokens.data.refresh_token);
      setLastAccessTokenUpdatedAtEpoch(new Date().getTime());
      setTimeout(() => {
        updateAccessTokenAndRefreshToken();
      }, (new Date().getTime()) + (ACCESS_TOKEN_TTL));
    }).catch((error) => {
      if (
        error
        && error.response
      ) {
        if (error.response.status === 401) {
          logoutUser();
        } else {
          setTimeout(() => {
            if (retryCount > 1) {
              updateAccessTokenAndRefreshToken(retryCount - 1);
            } else {
              logoutUser();
            }
          }, 2000);
        }
      }
    });
  } else if (!Number(lastAccessTokenUpdatedAtEpoch)) {
    logoutUser();
  } else {
    const updateTokenAfter = ((
      Number(lastAccessTokenUpdatedAtEpoch) + (ACCESS_TOKEN_TTL)
    ) - new Date().getTime());
    setTimeout(() => {
      updateAccessTokenAndRefreshToken();
    }, updateTokenAfter);
  }
};
