import React, { useEffect } from 'react';
import style from './index.module.scss';
import BrandCard from './brand-card';
import {
  getBrands, setBrandId, setBrands,
} from '../../utilities/storage';
import brandApis from '../../apis/brand';

function Brands() {
  const brands = getBrands();
  const isBrandsAvailable = (brands || []).length;
  const { location } = window;

  const onSelectBrand = async (brand = {}) => {
    await setBrandId(brand.id);
    location.replace(location.origin);
  };

  useEffect(() => {
    brandApis.brandList().then((res) => {
      setBrands(res.data.data);
    }).catch(() => {
      //
    });
  }, []);

  return (
    <div
      className={`px-4 ${style.brandContainer}`}
    >
      {/* Brand Market-Place Header */}
      <div
        className="py-4 text-center font-extrabold text-3xl"
      >
        Select Brands
      </div>
      {/* Brand Cards */}
      {
        isBrandsAvailable ? (
          <div
            className={`flex justify-center ${style.brandBody}`}
          >
            <div
              className={`flex justify-center ${style.brandSection}`}
            >
              {
                brands.map((item) => (
                  <BrandCard
                    key={item.id}
                    brand={item}
                    onSelectBrand={onSelectBrand}
                  />
                ))
              }
            </div>
          </div>
        ) : (
          <div
            className="py-6 px-3 text-center text-"
          >
            You do not have registered any brand with us.
          </div>
        )
      }
    </div>
  );
}

Brands.defaultProps = {
};

Brands.propTypes = {
};

export default Brands;
