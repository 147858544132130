/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import style from './index.module.scss';
import OrderCard from './order-card';
import OrderTab from './order-tab';
import CircularSpinner from '../../components/loader';
import orderApis from '../../apis/order';
import { getBrandId } from '../../utilities/storage';

function Orders() {
  const brandId = getBrandId();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const t = searchParams.get('t');
  const [selectedTab, setTab] = useState(t || 'PENDING');
  const [orders, setOrders] = useState({
    loading: true,
    error: false,
    data: null,
  });

  const getOrders = () => {
    const orderStage = selectedTab === 'CLOSED' ? 'closed' : selectedTab === 'IN_PROGRESS' ? 'in-progress' : 'pending';
    setOrders({
      ...orders,
      loading: true,
      error: false,
      data: null,
    });
    orderApis.orderList(orderStage, brandId).then((res) => {
      setOrders({
        ...orders,
        loading: false,
        error: false,
        data: res.data,
      });
    }).catch(() => {
      setOrders({
        ...orders,
        loading: false,
        error: true,
        data: null,
      });
    });
  };

  // const handleAppendData = (event) => {
  //   // if (orders.error || orders.loading || (orders.data && !orders.data.next)) {
  //   //   return;
  //   // }
  //   const {
  //     offsetHeight, scrollTop, scrollHeight,
  //   } = event.target;
  //   console.log(event, offsetHeight, scrollTop, scrollHeight);
  //   // if (scrollHeight - offsetHeight - scrollTop < 10
  //   //   || offsetHeight + scrollTop === scrollHeight) {
  //   //   this.loadOrders();
  //   // }
  // };

  useEffect(() => {
    getOrders();
  }, [selectedTab]);

  return (
    <div
      className={style.orderContainer}
      // onScroll={(event) => handleAppendData(event)}
    >
      <div
        className={style.orderBody}
      >
        <div
          className="sticky top-0"
        >
          {/* Tabs */}
          <OrderTab
            selectedTab={selectedTab}
            onSelectTab={(tab) => {
              setTab(tab);
              navigate(`/orders?t=${tab}`);
            }}
          />
        </div>

        {
          (() => {
            let ordersJSX = null;
            if (orders.loading) {
              ordersJSX = (
                <div
                  className="p-4 flex justify-center"
                >
                  <CircularSpinner />
                </div>
              );
            } else if (orders.error) {
              ordersJSX = (
                <div
                  className="flex justify-center text-red p-4"
                >
                  Oops Something Went Wrong
                </div>
              );
            } else if (orders.data && orders.data.count === 0) {
              ordersJSX = (
                <div
                  className="flex justify-center text-gray p-4"
                >
                  No Orders Found
                </div>
              );
            } else if (orders.data) {
              ordersJSX = (
                <div
                  className={` mt-4 flex justify-center ${style.orderSection}`}
                >
                  {/* Orders Cards */}
                  {
                    orders.data.data.map((item) => (
                      <OrderCard
                        key={item.orderId}
                        order={{
                          ...item,
                          deliveryAddress: null,
                        }}
                      />
                    ))
                  }
                </div>
              );
            }
            return ordersJSX;
          })()
        }
      </div>
    </div>
  );
}

export default Orders;
