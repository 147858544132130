/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';
import { getBrandId } from '../../../utilities/storage';

function BrandCard(props) {
  const { brand, onSelectBrand } = props;
  const brandId = getBrandId();

  return (
    <div
      role="button"
      className={`bg-white mb-4 border ${
        style.brandCard
      } ${
        (
          (brandId === brand.id)
        ) ? 'border-green' : 'border-gray'
      }`}
      onClick={() => {
        onSelectBrand(brand);
      }}
    >
      <div
        className="font-extrabold text-xl"
      >
        {brand.businessName}
      </div>
      <div
        className="truncate"
      >
        {brand.description}
      </div>
    </div>
  );
}

BrandCard.propTypes = {
  brand: PropTypes.shape({
    id: PropTypes.string,
    businessName: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  onSelectBrand: PropTypes.func.isRequired,
};

export default BrandCard;
