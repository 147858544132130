/* eslint-disable default-param-last */
import { getCurrentStatus } from '../utils';
import { orderLoadAction } from './actions';

const initState = {
  requestsProcessing: {
    [orderLoadAction.types.ORDER_LOAD_REQUEST]: false,
  },
  requestsFailure: {
    [orderLoadAction.types.ORDER_LOAD_REQUEST]: false,
  },
  orders: null,
  orderDetails: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    // Login Requests
    case orderLoadAction.types.ORDER_LOAD_REQUEST: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          orderLoadAction.types.ORDER_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          orderLoadAction.types.ORDER_LOAD_REQUEST,
          true,
        ),
      });
    }

    case orderLoadAction.types.ORDER_LOAD_SUCCESS: {
      const { brands } = action.payload;
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          orderLoadAction.types.ORDER_LOAD_REQUEST,
          false,
        ),
        brands,
      });
    }

    case orderLoadAction.types.ORDER_LOAD_FAILURE: {
      return ({
        ...state,
        requestsProcessing: getCurrentStatus(
          state.requestsProcessing,
          orderLoadAction.types.ORDER_LOAD_REQUEST,
          false,
        ),
        requestsFailure: getCurrentStatus(
          state.requestsFailure,
          orderLoadAction.types.ORDER_LOAD_REQUEST,
          true,
        ),
      });
    }

    default:
      return state;
  }
};
