import Axios from 'axios';
import {
  apiUrls, getParams, setAuthorizationHeaders, setHeaders,
} from '../utils';
import { getBrandId } from '../../utilities/storage';

const { newOOBaseUrl } = apiUrls();

const brandId = getBrandId();

const productApis = {
  productList: (cancelToken, param = {}) => {
    const productList = `${newOOBaseUrl}brand/product/`;
    return Axios({
      url: productList,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams({
        ...param,
        brand_id: brandId,
      }),
      cancelToken,
    });
  },

  productDetails: (cancelToken, productId, param = {}) => {
    const productDetails = `${newOOBaseUrl}brand/product/${productId || ''}`;
    return Axios({
      url: productDetails,
      method: 'GET',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams({
        ...param,
        brand_id: brandId,
      }),
      cancelToken,
    });
  },

  productDetailsPatch: (cancelToken, param = {}, productId = '', data = {}) => {
    const productDetailsPatch = `${newOOBaseUrl}brand/product/${productId || ''}`;
    return Axios({
      url: productDetailsPatch,
      method: 'PATCH',
      headers: setHeaders(setAuthorizationHeaders()),
      params: getParams({
        ...param,
        brand_id: brandId,
      }),
      data,
      cancelToken,
    });
  },
};

export default productApis;
