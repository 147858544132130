import React from 'react';
import PropTypes from 'prop-types';
import style from './index.module.scss';

function CircularSpinner(props) {
  const { width, height } = props;
  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
      className={style.loader}
    />
  );
}

CircularSpinner.defaultProps = {
  width: 36,
  height: 36,
};

CircularSpinner.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default CircularSpinner;
